const LinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M13.8799 10C13.8794 11.0609 13.4574 12.0781 12.7069 12.8279C11.9564 13.5777 10.9387 13.9986 9.87788 13.998C8.81702 13.9975 7.79981 13.5756 7.05004 12.8251C6.30027 12.0745 5.87935 11.0569 5.87988 9.99605C5.88041 8.93518 6.30235 7.91798 7.05287 7.16821C7.80339 6.41844 8.82102 5.99752 9.88188 5.99805C10.9427 5.99858 11.96 6.42051 12.7097 7.17103C13.4595 7.92155 13.8804 8.93918 13.8799 10ZM13.9999 16.96H5.99988V42H13.9999V16.96ZM26.6399 16.96H18.6799V42H26.5599V28.86C26.5599 21.54 36.0999 20.86 36.0999 28.86V42H43.9999V26.14C43.9999 13.8 29.8799 14.26 26.5599 20.32L26.6399 16.96Z"
        fill="#121A31"
      />
    </svg>
  );
};

export default LinkedIn;

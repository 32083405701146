import React from "react";

import card1 from "../assets/why-us-c1.svg";
import card2 from "../assets/why-us-c2.svg";
import card3 from "../assets/why-us-c3.svg";
import card4 from "../assets/why-us-c4.svg";
import card5 from "../assets/why-us-c5.svg";

const Feature = ({ Icon, text }) => (
  <div className="flex flex-col items-center p-6 mb-6 md:mb-0 w-[10rem]">
    <img src={Icon} alt={text} className="h-16 w-16 mb-4" />
    <p className="text-center text-sm">{text}</p>
  </div>
);

const WhyUsSection = () => {
  return (
    <>
      <section
        className={"container bg-bg-secondary p-6 rounded-lg shadow-mdr"}
        id="WhyUs"
      >
        <h2 className="text-2xl lg:text-3xl text-center font-semibold py-8">
          Why Dihadi?
        </h2>
        <div className="flex flex-wrap justify-around text-xl text-center font-semibold mb-8 gap-8">
          <Feature Icon={card1} text="Local Opportunities" />
          <Feature Icon={card2} text="Seamless Job Matching" />
          <Feature Icon={card3} text="Variety of Jobs" />
          <Feature Icon={card4} text="Flexible Scheduling" />
          <Feature Icon={card5} text="User-Friendly Platform" />
        </div>
      </section>
    </>
  );
};

export default WhyUsSection;

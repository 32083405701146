import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Privacy from '../components/Privacy';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Privacy />
      <Footer />
    </>
  );
}

export default PrivacyPolicy
import React from "react";

import logo from "../assets/logo.svg";

import LinkedIn from "../icons/LinkedIn";
import Twitter from "../icons/Twitter";
import Facebook from "../icons/Facebook";
import Internet from "../icons/Internet";

const Footer = () => {
  return (
    <>
      <div className={"pt-8 lg:pt-16 text-primary bg-bg-secondary"}>
        <section className="container">
          <div
            className={
              "flex flex-col items-center lg:flex-row lg:justify-between lg:items-center"
            }
          >
            <div className={"flex flex-col"}>
              <div className={"mx-auto lg:mx-0"}>
                <img src={logo} alt={"Logo"} className={"mb-6"} />
              </div>

              <h1
                className={
                  "text-center lg:text-left my-1 text-base font-semibold"
                }
              >
                NemaTech dynamics Private limited <br />
                Abhishek nema | Co - founder <br />
                Aditya nema | Co - Founder <br />
              </h1>

              <p className={"text-center lg:text-left text-sm text-quaternary"}>
                BHOPAL | INDORE (M.P.)
                <br />
                +91 9171923933
              </p>
            </div>

            <div className={"pt-12 lg:pt-0"}>
              <h1
                className={
                  "my-1 text-base font-semibold text-center lg:text-left"
                }
              >
                Follow us
              </h1>
              <div className={"flex gap-4"}>
                <LinkedIn />
                <Twitter />
                <Facebook />
                <Internet />
              </div>
            </div>
          </div>
        </section>

        <section className="container py-4">
          <div className={"text-center text-quaternary text-xs"}>
            @{new Date().getFullYear()} ©️ Nematech dynamics private limited.
            All rights reserved
          </div>
          <div className={"text-center text-quaternary text-xs"}>
            Made by{" "}
            <a
              href="https://japangrabtech.com/"
              className="text-blue-600"
              target="_blank"
            >
              <u> Japan Grab Technologies </u>
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;

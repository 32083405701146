import React from "react";

import HeroImg from "../assets/hero-section.svg";
import ButtonWrapper from "./DownloadButton";

const HeroSection = () => {
  const downloadApk = () => {
    const url = `${process.env.PUBLIC_URL}/app-release.apk`;

    const link = document.createElement("a");
    link.href = url;
    link.download = "app-release.apk";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <section className={"container my-5 lg:my-10 "}>
        <div className={"flex flex-col md:flex-row items-center"}>
          <div className={"w-3/4 "}>
            <h1 className="text-2xl lg:text-4xl font-semibold mb-4 text-primary">
              Welcome to Dihadi : <br />
              Your Gateway to Part-Time Opportunities
            </h1>
            <p className="text-md lg:text-lg text-quaternary my-8">
              Are you in search of flexible and rewarding part-time jobs? Look
              no further than Dihadi - your go-to job application platform for
              skilled and unskilled part-time positions. Whether you're an
              experienced professional or just starting out, Dihadi connects job
              seekers like you with local employers seeking your specific
              skills.
            </p>
          </div>

          <div>
            <img src={HeroImg} alt="Hero Section Illustration" />
          </div>
        </div>

        <div className="text-center mt-10">
          <div onClick={downloadApk}>
            <ButtonWrapper />
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;

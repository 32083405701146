import React from "react";

import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import WhyUsSection from "../components/WhyUsSection";
import JoinUsSection from "../components/JoinUsSection";
import Steps from "../components/Steps";
import Footer from "../components/Footer";

const Landing = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <WhyUsSection />
      <Steps />
      <JoinUsSection />
      <Footer />
    </>
  );
};

export default Landing;

const Internet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M32.72 28C32.88 26.68 33 25.36 33 24C33 22.64 32.88 21.32 32.72 20H39.48C39.8 21.28 40 22.62 40 24C40 25.38 39.8 26.72 39.48 28M29.18 39.12C30.38 36.9 31.3 34.5 31.94 32H37.84C35.9023 35.3366 32.8282 37.864 29.18 39.12ZM28.68 28H19.32C19.12 26.68 19 25.36 19 24C19 22.64 19.12 21.3 19.32 20H28.68C28.86 21.3 29 22.64 29 24C29 25.36 28.86 26.68 28.68 28ZM24 39.92C22.34 37.52 21 34.86 20.18 32H27.82C27 34.86 25.66 37.52 24 39.92ZM16 16H10.16C12.0777 12.6544 15.1496 10.123 18.8 8.88C17.6 11.1 16.7 13.5 16 16ZM10.16 32H16C16.7 34.5 17.6 36.9 18.8 39.12C15.1572 37.8633 12.0897 35.3354 10.16 32ZM8.52 28C8.2 26.72 8 25.38 8 24C8 22.62 8.2 21.28 8.52 20H15.28C15.12 21.32 15 22.64 15 24C15 25.36 15.12 26.68 15.28 28M24 8.06C25.66 10.46 27 13.14 27.82 16H20.18C21 13.14 22.34 10.46 24 8.06ZM37.84 16H31.94C31.314 13.5229 30.3871 11.1318 29.18 8.88C32.86 10.14 35.92 12.68 37.84 16ZM24 4C12.94 4 4 13 4 24C4 29.3043 6.10714 34.3914 9.85786 38.1421C11.715 39.9993 13.9198 41.4725 16.3463 42.4776C18.7728 43.4827 21.3736 44 24 44C29.3043 44 34.3914 41.8929 38.1421 38.1421C41.8929 34.3914 44 29.3043 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4Z"
        fill="#121A31"
      />
    </svg>
  );
};

export default Internet;

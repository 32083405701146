import React from "react";

import Button, { ButtonSecondary } from "./UI/Button";

import JoinUsImg from "../assets/join-us.svg";

const JoinUsSection = () => {
  const handleWhatsAppClick = (e) => {
    e.preventDefault(); // Prevent the anchor tag from navigating.
    console.log("hello");
    window.open("https://wa.me/9171923933", "_blank", "noopener,noreferrer");
  };

  const handleMailClick = (e) => {
    e.preventDefault(); // Prevent the anchor tag from navigating.
    console.log("hello");
    window.open(
      "mailto:meera.gupta1910@gmail.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <div className="container mt-8 lg:mt-16 text-primary" id="JoinUs">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl lg:text-3xl text-center font-semibold py-8 ">
            Join Dihadi Today: Unlock Your Part-Time Potential!
          </h1>

          <p
            className={
              "text-quaternary w-3/4 text-justify lg:text-center text-sm lg:text-base pb-6"
            }
          >
            Discover the convenience of local part-time employment with Dihadi.
            Sign up today, create your profile, and embark on a journey towards
            fulfilling and flexible part-time opportunities right in your
            neighborhood. Your next part-time adventure awaits - seize it with
            Dihadi!
          </p>

          <h1 className="text-2xl lg:text-4xl text-center font-semibold py-12 ">
            Get Notified
            <br />
            When we Launch
          </h1>

          <div
            className={"w-[80%] lg:w-1/2 flex justify-center mx-auto space-x-4"}
          >
            <a
              href="mailto:abc@gmail.com"
              onClick={handleMailClick}
              rel="noreferrer"
              target="_blank"
            >
              <ButtonSecondary>Send Message</ButtonSecondary>
            </a>

            <a
              href="https://wa.me/9171923933"
              onClick={handleWhatsAppClick}
              rel="noreferrer"
              target="_blank"
            >
              <Button>WhatsApp</Button>
            </a>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <img src={JoinUsImg} alt="Join us at Dihadi" className="mt-12" />
        </div>
      </div>
    </>
  );
};

export default JoinUsSection;

import React from 'react'

const Privacy = () => {
  return (
    <section className={`container z-20 font-primary`}>
      {/* policies */}
      <div className="flex flex-col gap-2 pt-12 py-[4rem]">
        <h1 className="text-2xl pb-[4rem] font-bold justify-center items-center flex font">
          <u>PRIVACY POLICY:</u>
        </h1>
        <h2 className="text-xl font-semibold py-4 pt-6">INTRODUCTION:</h2>
        <p>
          Dihadi, a platform operated by NemaTech Dynamics Private Limited,
          serves as a digital marketplace facilitating connections between local
          job seekers and employers seeking to fill part-time positions. Our
          platform's core function is to streamline the job matching process by
          providing a centralized hub for individuals to discover and apply for
          suitable opportunities. It's important to note that Dihadi acts as a
          neutral intermediary, connecting potential employees and employers
          without involvement in the employment relationship itself. This Data
          Privacy Policy outlines our commitment to safeguarding user
          information, detailing how we collect, utilize, store, and protect
          personal data shared through our platform.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">DATA COLLECTION:</h2>
        <p>
          To facilitate the efficient matching of job seekers and employers,
          Dihadi collects a minimal amount of personal information. This data is
          essential for creating user profiles, enabling communication, and
          providing the core functionality of the platform.
        </p>
        <p>The specific information we collect includes:</p>
        <p className="pl-10">
          <strong>• Basic User Information: </strong>Name, phone number, and
          email address. This data is required to establish user identities,
          facilitate communication, and verify account ownership.
        </p>
        <p className="pl-10">
          <strong>• Login Credentials: </strong>We collect login credentials,
          such as email address and password, to authenticate users and protect
          their accounts from unauthorized access.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          DATA STORAGE AND RETENTION:
        </h2>
        <p>
          To ensure the security and integrity of user data, Dihadi employs
          robust security measures and adheres to strict data retention
          policies.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">DATA STORAGE:</h2>
        <p>
          To ensure the security and accessibility of user data, Dihadi utilizes
          a robust and secure database infrastructure powered by MongoDB. This
          database platform is chosen for its scalability, flexibility, and
          ability to handle large volumes of data efficiently.
        </p>
        <p>
          Our database environment is hosted on secure servers within a
          controlled data center. These servers are equipped with advanced
          security features, including firewalls, intrusion detection systems,
          and access controls, to protect against unauthorized access and data
          breaches.
        </p>
        <p>
          By storing user data in a centralized database, we can efficiently
          manage, retrieve, and analyze information to optimize the job matching
          process. Additionally, this approach enables us to implement robust
          data backup and recovery procedures to safeguard against data loss due
          to technical failures or unforeseen circumstances.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">DATA RETENTION:</h2>
        <p>
          Dihadi adheres to a strict data retention policy to protect user
          privacy and comply with applicable data protection regulations. We
          retain user data only for as long as it is necessary to fulfill the
          purposes outlined in this Privacy Policy or as required by law.
        </p>
        <p className="pl-10">
          <strong>• Active User Data: </strong>For users who actively engage
          with the platform, we retain personal information for a period of one
          year from the date of their last interaction. This period allows us to
          maintain a seamless user experience, facilitate communication, and
          address any potential issues or disputes.
        </p>
        <p className="pl-10">
          <strong>• Inactive User Data: </strong> If a user's account becomes
          inactive for a continuous period of one year, we may securely delete
          their personal information. However, we reserve the right to retain
          anonymized data for statistical and analytical purposes, which does
          not identify individual users.
        </p>
        <p className="pl-10">
          <strong>• Legal and Compliance Obligations: </strong>In certain
          circumstances, we may be required to retain user data for longer
          periods to comply with legal obligations, such as tax, accounting, or
          regulatory requirements. In such cases, we will securely store the
          necessary information in accordance with applicable laws and
          regulations.
        </p>
        <p>
          We regularly review our data retention practices ensuring compliance
          with evolving legal and regulatory frameworks.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">DATA SECURITY:</h2>
        <p>
          Safeguarding user data is a paramount concern for Dihadi. We employ a
          multi-layered security approach to protect personal information from
          unauthorized access, disclosure, alteration, or destruction. Our
          comprehensive security measures include:
        </p>
        <p className="pl-10">
          <strong>• Encryption: </strong>All data transmitted between users and
          our platform is encrypted using industry-standard protocols such as
          HTTPS and TLS. Additionally, data stored within our database is
          encrypted at rest using robust encryption algorithms like SHA-256.
        </p>
        <p className="pl-10">
          <strong>• Access Controls: </strong>We implement strict access
          controls to limit access to user data to authorized personnel only.
          Employees are required to undergo security training and adhere to
          strict confidentiality obligations.
        </p>
        <p className="pl-10">
          <strong>• Network Security: </strong>Our network infrastructure is
          protected by firewalls, intrusion detection and prevention systems,
          and other advanced security technologies to prevent unauthorized
          access and malicious attacks.
        </p>
        <p className="pl-10">
          <strong>• Regular Security Audits and Monitoring: </strong>We conduct
          regular security assessments and vulnerability scans to identify and
          address potential security weaknesses. Our systems are continuously
          monitored for suspicious activities to detect and respond to threats
          promptly.
        </p>
        <p className="pl-10">
          <strong>• Incident Response Plan: </strong> We have a comprehensive
          incident response plan in place to handle data breaches or security
          incidents effectively. This plan outlines steps to contain the breach,
          notify affected individuals, and restore normal operations.
        </p>
        <p>
          While we strive to maintain the highest security standards, it is
          important to note that no system is entirely immune to security risks.
          We encourage users to protect their personal information by using
          strong passwords, avoiding phishing attempts, and being cautious when
          sharing information online.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          DATA TRANSFER TO THIRD PARTIES:
        </h2>
        <p>
          <strong>
            Dihadi does not share, sell, or rent user data to third parties for
            marketing purposes.
          </strong>
        </p>
        <p>
          We may share user data with trusted third-party service providers who
          assist us in operating our platform and providing services to you.
          These service providers are contractually obligated to protect user
          data and may only use it for the specific purposes for which it was
          provided.
        </p>
        <p>Examples of such third-party service providers may include:</p>
        <p className="pl-10">
          <strong>• Payment processors: </strong>To process payments for premium
          features or services.
        </p>
        <p className="pl-10">
          <strong>• Cloud service providers: </strong>To store and manage user
          data securely.
        </p>
        <p className="pl-10">
          <strong>• Analytics providers: </strong> To help us understand user
          behavior and improve our platform.
        </p>
        <p className="pl-10">
          <strong>• Customer support providers: </strong>To assist us in
          providing customer support.
        </p>
        <p>
          We will take appropriate measures to ensure that these third-party
          service providers have adequate data protection measures in place.
        </p>
        <p>
          If we undergo a business transaction, such as a merger, acquisition,
          or sale of assets, your information may be transferred to the
          acquiring entity. We will notify you of any such change in ownership
          or control of your information.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          DATA BREACH NOTIFICATION:
        </h2>
        <p>
          In the event of a data breach or security incident involving user
          data, Dihadi will take immediate steps to investigate the incident,
          contain the breach, and mitigate potential harm to affected
          individuals.
        </p>
        <p>
          We will notify affected users of the data breach without undue delay,
          consistent with applicable laws and regulations. The notification will
          include details about the nature and scope of the breach, the types of
          personal information affected, and steps users can take to protect
          themselves.
        </p>
        <p>
          Dihadi will cooperate fully with law enforcement and regulatory
          authorities in the event of a data breach.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          REGULAR AUDITS AND COMPLIANCE:
        </h2>
        <p>
          To ensure ongoing adherence to data protection regulations and
          maintain the highest security standards, Dihadi conducts comprehensive
          and regular audits of its data handling practices, systems, and
          procedures. These audits serve as a critical component of our
          commitment to data privacy and security.
          <br />
          Our audit program includes:
        </p>
        <p className="pl-10">
          <strong>• Internal Audits: </strong>Regular assessments conducted by
          our internal security and compliance teams to evaluate our data
          protection practices against relevant legal and regulatory frameworks.
        </p>
        <p className="pl-10">
          <strong>• External Audits: </strong>Periodic evaluations performed by
          independent third-party auditors to provide an unbiased assessment of
          our data protection measures and identify potential vulnerabilities.
        </p>
        <p>Audit scope typically encompasses:</p>
        <p className="pl-10">• Data collection and processing activities</p>
        <p className="pl-10">• Data storage and security measures</p>
        <p className="pl-10">• Access controls and user authentication</p>
        <p className="pl-10">• Data retention and deletion procedures</p>
        <p className="pl-10">• Incident response plans</p>
        <p className="pl-10">
          • Compliance with applicable laws and regulations
        </p>
        <p>
          Findings from these audits are meticulously documented and analyzed to
          identify areas for improvement. Corrective action plans are developed
          and implemented to address any identified deficiencies. We maintain
          comprehensive records of audit findings, corrective actions, and
          verification activities.
          <br /> By conducting regular audits, we demonstrate our commitment to
          accountability and transparency in data protection.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">CONCLUSION:</h2>
        <p>
          Dihadi is committed to protecting the privacy and security of our
          users. We collect only the minimal personal information necessary to
          facilitate the job matching process and do not collect or store any
          sensitive personal data. We employ robust security measures to
          safeguard user data and adhere to strict data retention policies. This
          Privacy Policy has been drafted in compliance with the Indian Digital
          Personal Data Protection Act, 2023.
        </p>
        <p>
          Your trust is important to us. If you have any questions or concerns
          about our privacy practices, please contact us at +91 9171923933. By
          using the Dihadi platform, you acknowledge that you have read,
          understood, and agreed to the terms of this Privacy Policy.
        </p>
        <h1 className="text-2xl py-[4rem] font-bold justify-center items-center flex font">
          <u>COOKIE POLICY:</u>
        </h1>
        <h2 className="text-xl font-semibold py-4 pt-6">What are Cookies?</h2>
        <p>
          Cookies are small text files that are stored on your device when you
          visit a website. They are widely used to make websites work more
          efficiently, as well as to provide information to the website owner.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">How We Use Cookies </h2>
        <p>Dihadi uses cookies for the following purposes:</p>
        <p className="pl-10">
          • To improve your user experience by remembering your preferences and
          settings.
        </p>
        <p className="pl-10">
          • To analyse website traffic and usage patterns to enhance our
          services.
        </p>
        <p className="pl-10">• To deliver targeted advertising.</p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          Types of Cookies We Use:
        </h2>
        <p className="pl-10">
          <strong>• Essential Cookies: </strong>These cookies are necessary for
          the website to function properly and cannot be switched off in our
          systems. They are usually only set in response to actions made by you
          which amount to a request for services, such as setting your privacy
          preferences, logging in or filling in forms.
        </p>
        <p className="pl-10">
          <strong>• Performance Cookies: </strong>These cookies collect
          information about how visitors use our website, such as which pages
          are visited most often. This data is used to optimize the website and
          improve the user experience.
        </p>
        <p className="pl-10">
          <strong>• Functional Cookies: </strong>These cookies allow the website
          to remember your choices and provide enhanced features. For example,
          they may be used to remember your preferred language or the region you
          are in.
        </p>
        <p className="pl-10">
          <strong>• Targeting Cookies: </strong>These cookies are used to
          deliver advertising that is more relevant to you and your interests.
          They are also used to limit the number of times you see an
          advertisement and to measure the effectiveness of advertising
          campaigns.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          Controlling Cookies:
        </h2>
        <p>
          You can control and manage cookies through your browser settings. Most
          browsers allow you to block cookies, but please note that this may
          affect the functionality of the website.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          Changes to Our Cookie Policy:
        </h2>
        <p>
          We may update our Cookie Policy from time to time. Any changes will be
          posted on this page.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">Contact Us:</h2>
        <p>
          If you have any questions about our Cookie Policy, please contact us
          at +91 9171923933
        </p>
      </div>
    </section>
  );
}

export default Privacy
import React from 'react'

const Terms = () => {
  return (
    <section className={`container z-20 font-primary`}>
      <div className="flex flex-col mb-[2rem] gap-2">
        <h1 className=" text-2xl flex justify-center items-center mt-10">
          <strong>Terms and Conditions</strong>
        </h1>
        <h2 className="text-xl font-semibold py-4 pt-6">1. Introduction:</h2>
        <p>
          <strong>1.1</strong> Dihadi is an online platform designed to
          facilitate connections between employers seeking part-time workers and
          individuals looking for flexible employment opportunities. As a
          technology-driven intermediary, Dihadi provides a virtual marketplace
          where users can interact, search, and potentially establish employment
          relationships.
        </p>
        <p>
          <strong>1.2 </strong>Our platform operates on a self-service model,
          empowering both employers and employees to independently create and
          manage their profiles, post job listings, and initiate contact. Dihadi
          does not involve itself in the hiring process or employment contracts,
          acting solely as a neutral platform for interaction.
        </p>
        <p>
          <strong>1.3 </strong>By using Dihadi, you agree to comply with these
          Terms and Conditions, which outline the terms of service, limitations
          of liability, and other important information regarding the use of our
          platform.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          2. User Eligibility:
        </h2>
        <p>
          <strong>2.1</strong>To use Dihadi, you must be at least 18 years old
          and legally capable of forming a binding contract under applicable
          law. If you are registering as an employer, you must have the legal
          authority to bind the entity you represent.
        </p>
        <p>
          <strong>2.2</strong> By registering on Dihadi, you represent and
          warrant that:
        </p>
        <p>
          * All information provided by you is accurate, complete, and current.
        </p>
        <p>
          * You will comply with all applicable laws, rules, and regulations in
          connection with your use of the platform.
        </p>
        <p>
          * You will not use the platform for any unlawful or prohibited
          purpose.
        </p>
        <p>
          * You will not engage in any activity that interferes with or disrupts
          the platform or the enjoyment of the platform by other users.
        </p>
        <p>
          * You will not attempt to gain unauthorized access to any portion of
          the platform or any other systems or networks connected to the
          platform.
        </p>
        <p>
          <strong>2.3 </strong>Dihadi reserves the right to verify the
          eligibility of any user and to suspend or terminate the account of any
          user who fails to meet the eligibility criteria.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">3. Platform Usage:</h2>
        <p>
          <strong>3.1 </strong>Dihadi provides a digital platform that connects
          employers seeking part-time workers with individuals looking for
          flexible employment opportunities. Users can create profiles, search
          for potential matches, and communicate directly.
        </p>
        <p>
          <strong>3.2 Employer Responsibilities: </strong> Employers using
          Dihadi are responsible for:
        </p>
        <p>* Posting accurate and complete job descriptions. </p>
        <p>* Complying with all applicable labor laws and regulations. </p>
        <p>
          * Independently verifying the qualifications and credentials of
          potential employees.
        </p>
        <p>
          * Establishing terms and conditions of employment, including
          compensation, benefits, and work arrangements.
        </p>
        <p>
          <strong>3.3 Employee Responsibilities: </strong> Employees using
          Dihadi are responsible for:
        </p>
        <p>* Providing accurate and complete information on their profiles.</p>
        <p>
          * Complying with the terms and conditions of employment as agreed upon
          with the employer.
        </p>
        <p>* Adhering to any applicable labor laws and regulations.</p>
        <p>
          <strong>3.4 </strong>Dihadi does not guarantee the suitability of any
          job or candidate, nor does it endorse any specific employer or
          employee. Users are encouraged to exercise due diligence in their
          interactions and transactions.
        </p>
        <p>
          <strong>3.5 </strong>Dihadi reserves the right to remove or modify any
          content or user-generated information that violates these Terms and
          Conditions or is deemed inappropriate.
        </p>

        <h2 className="text-xl font-semibold py-4 pt-6">
          4. Limitation of Liability:
        </h2>
        <p>
          <strong>4.1</strong>To the maximum extent permitted by law, Dihadi
          shall not be liable for any direct, indirect, incidental,
          consequential, or punitive damages arising out of or in connection
          with the use of the platform, including but not limited to:
        </p>
        <p className="pl-10">
          • Loss of profits, revenue, or business opportunities.{" "}
        </p>
        <p className="pl-10">• Loss or damage to data or systems.</p>
        <p className="pl-10">• Claims of defamation, libel, or slander.</p>
        <p className="pl-10">• Any disputes or claims between users.</p>
        <p>
          <strong>4.2</strong>Dihadi makes no warranties or representations
          about the accuracy, completeness, or reliability of any information or
          content provided on the platform. Users rely on the information
          provided at their own risk.
        </p>
        <p>
          <strong>4.3</strong> Dihadi is not responsible for the conduct of
          users, including but not limited to any fraudulent, harmful, or
          illegal activities.
        </p>
        <p>
          <strong>4.4</strong> 4 Dihadi does not guarantee the performance or
          quality of services provided by employers or the qualifications of
          employees. Users are solely responsible for conducting due diligence
          and verifying the information provided by other users.
        </p>
        <p>
          <strong>4.5</strong> The limitations of liability set forth in this
          section shall apply even if Dihadi has been advised of the possibility
          of such damages.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          5. Intellectual Property:
        </h2>
        <p>
          <strong>5.1 Dihadi’s Ownership: </strong> Dihadi owns and retains all
          right, title, and interest, including all intellectual property
          rights, in and to the platform, its underlying technology, software,
          content, trademarks, service marks, logos, and other proprietary
          materials.
        </p>
        <p>
          <strong>5.2 User Content: </strong>While Dihadi does not claim
          ownership of content submitted by users (such as resumes, job
          descriptions, or messages), by posting such content, you grant Dihadi
          a non-exclusive, worldwide, royalty-free, transferable, sublicensable
          license to use, reproduce, distribute, modify, create derivative works
          of, and publicly display and perform such content in connection with
          the operation of the platform.
        </p>
        <p>
          <strong>5.3 User Representations: </strong>You represent and warrant
          that you own or have the necessary rights to all content you submit to
          the platform and that such content does not infringe the intellectual
          property rights or other rights of any third party.
        </p>
        <p>
          <strong>5.4 Prohibited Use: </strong> You agree not to:
        </p>
        <p>
          * Copy, modify, or distribute any portion of the platform without
          Dihadi’s express written consent.
        </p>
        <p>
          * Use any data mining, robots, or similar data gathering and
          extraction methods.
        </p>
        <p>
          * Attempt to bypass or circumvent any security measures of the
          platform.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">6. Indemnification:</h2>
        <p>
          <strong>6.1 User Indemnification: </strong>You agree to indemnify,
          defend, and hold harmless Dihadi, its affiliates, officers, directors,
          employees, and agents from and against any and all claims, damages,
          liabilities, losses, costs, and expenses (including reasonable
          attorneys’ fees) arising out of or in any way connected with:
        </p>
        <p className="pl-10">• Your use of the platform.</p>
        <p className="pl-10">
          • Your violation of these Terms and Conditions.{" "}
        </p>
        <p className="pl-10">
          • Your violation of any law, rule, or regulation.
        </p>
        <p className="pl-10">
          • Your infringement of any intellectual property or other right of any
          third party.
        </p>
        <p className="pl-10">
          • Any disputes or claims arising between you and another user.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">7. Termination:</h2>
        <p>
          <strong>7.1 Termination by Dihadi: </strong>Dihadi may terminate your
          access to the platform, or any part thereof, with or without cause,
          with or without notice, at any time and for any reason.
        </p>
        <p>
          <strong>7.2 Termination by You: </strong> You may terminate your use
          of the platform at any time by providing written notice to Dihadi.
        </p>
        <p>
          <strong>7.3 Effects of Termination: </strong>Upon termination of your
          access to the platform, your right to use the platform and any content
          or data associated with your account will immediately cease. Dihadi
          may, but is not obligated to, delete your account and all associated
          data.
        </p>
        <p>
          <strong>7.4 Survival: </strong> Certain provisions of these Terms and
          Conditions, including but not limited to, intellectual property
          rights, indemnification, and limitation of liability, shall survive
          the termination of this Agreement.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">8. Governing Law:</h2>
        <p>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of Indore.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          9. Changes to Terms and Conditions:
        </h2>
        <p>
          Dihadi reserves the right to modify these Terms and Conditions at any
          time. Any changes will be effective immediately upon posting on the
          platform.
        </p>
        <h2 className="text-xl font-semibold py-4 pt-6">
          10. Contact Information:
        </h2>
        <p>
          For any questions or concerns regarding these Terms and Conditions,
          please contact +91 9171923933.
        </p>
      </div>
    </section>
  );
}

export default Terms
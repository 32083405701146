import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Terms from "../components/Terms";

const TermsConditions = () => {
  return (
    <>
      <Header />
        <Terms />
      <Footer />
    </>
  );
};

export default TermsConditions;

import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Landing from "./pages/Landing";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";


function App() {
  return (
    <div className="App font-primary">
      <Router>
        <Routes>
          <Route path="/" exact element={<Landing />} />
          <Route path="/terms" exact element={<TermsConditions />} />
          <Route path="/privacy" exact element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from "react";

const stepsArr = [
  {
    number: "1",
    title: "Sign Up and Create Your Profile",
    description:
      "Join Dihadi by creating a profile highlighting your skills, experience, and availability.",
  },
  {
    number: "2",
    title: "Browse Local Job Listings",
    description:
      "Explore a variety of part-time job opportunities available in your vicinity.",
  },
  {
    number: "3",
    title: "Apply with Confidence",
    description:
      "Apply for jobs that match your skills and interests. Customize your applications to stand out to potential employers.",
  },
  {
    number: "4",
    title: "Connect with Employers",
    description:
      "Communicate directly with local employers who are interested in your profile.",
  },
  {
    number: "5",
    title: "Get Hired and Start Earning",
    description:
      "Once you're matched with the right opportunity, start your part-time job and enjoy the benefits of flexible work.",
  },
];

const Steps = () => {
  return (
    <div className="container mx-auto my-10 text-primary" id="HowItWorks">
      <h2 className="text-2xl lg:text-3xl text-center font-semibold py-8 ">
        How It Works
      </h2>
      <div className="flex flex-wrap -mx-2 overflow-hidden ">
        {stepsArr.map((step, index) => (
          <div
            key={index}
            className="my-6 px-2 w-full overflow-hidden lg:w-1/2"
          >
            <div className="flex items-center mb-4">
              <span className="font-bold text-xl lg:text-2xl mr-2">
                {step.number}
              </span>
              <hr className="border-t-2 border-green-500 flex-grow" />
            </div>
            <h3 className="text-lg lg:text-xl font-semibold mb-2">
              {step.title}
            </h3>
            <p className="text-quaternary">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;

import React from "react";

const Button = ({ children, onClick }) => {
  return (
    <>
      <button
        className={`p-[8px] rounded-lg min-w-[136px] font-medium font-primary bg-tertiary hover:bg-secondary text-secondary hover:text-tertiary border-2 border-tertiary transition-all duration-300`}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

export const ButtonSecondary = ({ children, onClick }) => {
  return (
    <>
      <button
        className={`p-[8px] rounded-lg min-w-[136px] font-medium font-primary bg-secondary hover:bg-tertiary text-tertiary hover:text-secondary border-2 border-tertiary transition-all duration-300`}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

export default Button;

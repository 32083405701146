import React, { useState } from "react";

import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

import Button from "./UI/Button";

import logo from "../assets/logo.svg";

const Header = () => {
  const [hamOpen, setHamOpen] = useState(false);
  const [hamBurgerAnimation, setHumBurgerAnimation] = useState({
    upper: "h-1 w-8 bg-primary rounded-md ease-in-out duration-300 my-[2.5px]",
    middle: "h-1 w-8 bg-primary rounded-md ease-in-out duration-300 my-[2.5px]",
    lower: "h-1 w-8 bg-primary rounded-md ease-in-out duration-300 my-[2.5px]",
  });
  const [showMenuData, setShowMenuData] = useState(
    "w-[100vw] h-0 absolute z-20 bg-neutral-700 opacity-90 top-0 left-0 transition-all ease-in-out duration-300"
  );
  const [hideNavLinks, setHideNavLinks] = useState("hidden");

  const clickHandler = () => {
    if (!hamOpen) {
      setHumBurgerAnimation({
        upper:
          "h-1 w-8 bg-primary rotate-[45deg] translate-y-[0.56rem] rounded-md ease-in-out duration-300 my-[2.5px]",
        middle:
          "h-1 w-8 bg-transparent rounded-md ease-in-out duration-300 my-[2.5px]",
        lower:
          "h-1 w-8 bg-primary rotate-[-45deg] -translate-y-[0.56rem] rounded-md ease-in-out duration-300 my-[2.5px]",
      });
      setShowMenuData(
        "w-[100vw] h-auto absolute py-12  flex flex-col items-center z-20 bg-white top-0 left-0 right-0 transition-all ease-in-out duration-300"
      );
      setHideNavLinks("block");
      setHamOpen(true);
    } else if (hamOpen) {
      setHumBurgerAnimation({
        upper:
          "h-1 w-8 bg-primary rounded-md ease-in-out duration-300 my-[2.5px]",
        middle:
          "h-1 w-8 bg-primary rounded-md ease-in-out duration-300 my-[2.5px]",
        lower:
          "h-1 w-8 bg-primary rounded-md ease-in-out duration-300 my-[2.5px]",
      });
      setShowMenuData(
        "w-[100vw] h-0 absolute z-20 bg-neutral-700 opacity-90 top-0 left-0 transition-all ease-in-out duration-300"
      );
      setHideNavLinks("hidden");
      setHamOpen(false);
    }
  };
  return (
    <>
      <section className={`container z-20 font-primary font-semibold`}>
        <div className="flex w-full justify-between py-8 lg:flex-row">
          <Link to="/">
            {" "}
            <img src={logo} alt="Logo" draggable={false} />
          </Link>
          <div className="hidden lg:flex lg:items-center">
            <div className="mx-2 cursor-pointer px-2 font-syne text-text-primary transition-all duration-300 ease-in-out hover:underline">
              <Link to="/">Home</Link>
            </div>
            <div className="mx-2 cursor-pointer px-2 font-syne text-text-primary transition-all duration-300 ease-in-out hover:underline">
              <HashLink smooth to="/#WhyUs">
                Why Us
              </HashLink>
            </div>
            <div className="mx-2 cursor-pointer px-2 font-syne text-text-primary transition-all duration-300 ease-in-out hover:underline">
              <HashLink smooth to="/#HowItWorks">
                How it Works
              </HashLink>
            </div>
            <div className="mx-2 cursor-pointer px-2 font-syne text-text-primary transition-all duration-300 ease-in-out hover:underline">
              <a href="/terms">Terms</a>
            </div>
            <div className="mx-2 cursor-pointer px-2 font-syne text-text-primary transition-all duration-300 ease-in-out hover:underline">
              <a href="/privacy">Privacy</a>
            </div>
          </div>
          <div className="hidden lg:flex lg:items-center">
            <HashLink smooth to="/#JoinUs">
              <Button>Contact Us</Button>
            </HashLink>
          </div>
          <div
            onClick={clickHandler}
            className="relative z-30 flex cursor-pointer flex-col p-4 lg:hidden"
          >
            <div className={hamBurgerAnimation.upper}></div>
            <div className={hamBurgerAnimation.middle}></div>
            <div className={hamBurgerAnimation.lower}></div>
          </div>
        </div>

        <nav className={showMenuData}>
          <ul className={hideNavLinks}>
            <li className="my-6 text-center">
              <Link to="/">Home</Link>
            </li>
            <li className="my-6 text-center">
              <HashLink smooth to="/#WhyUs">
                Why Us
              </HashLink>
            </li>
            <li className="my-6 text-center">
              <HashLink smooth to="/#HowItWorks">
                How it Works
              </HashLink>
            </li>
            <li className="my-6 text-center">
              <a href="/terms">Terms & Conditions</a>
            </li>
            <li className="my-6 text-center">
              <a href="/privacy">Privacy Policy</a>
            </li>

            <li className="my-6 text-center">
              <HashLink smooth to="/#JoinUs">
                <Button>Contact Us</Button>
              </HashLink>
            </li>
          </ul>
        </nav>
      </section>
    </>
  );
};

export default Header;
